import React, { useState } from "react";
// import blipinho from "./blipinho.png";
import blipinhoThugLife from "./blipinho-thug-life.svg";
import blipinhoBone from "./blipinho-bone.svg";
import "./Home.scss";
import { VariantType } from "blip-ds/dist/types/components/toast/toast-interface";
import {
  logger,
  blip,
  segment,
  environment,
  appData,
  storage,
  FeatureFlag,
} from "packs-template-baseweb";
import { ILogArgs } from "packs-template-baseweb";

const logArgs: ILogArgs = {
  className: Home.name,
};

function Home(): JSX.Element {
  const [toastStatus, setToastStatus] = useState(false);
  const [toastText, setToastText] = useState("");
  const [toastIcon, setToastIcon] = useState("builder-publish-bot");
  const [toastTitle, setToastTitle] = useState("");
  const [toastVariant, setToastVariant] = useState<VariantType>("success");

  // Exemple function to call tracking service
  async function registerBotTracking(): Promise<void> {
    logArgs.methodName = registerBotTracking.name;

    const authorization = process.env.REACT_APP_BOT_AUTHORIZATION ?? "";
    const identity = process.env.REACT_APP_CONTACT_IDENTITY ?? "";
    const client = blip.configure(authorization);

    await client
      .trackAsync("Tracking de teste", "Ok! Tudo certo", identity)
      .then((resp: any) => {
        setToastTitle("Yeahhh!");
        setToastText("Bot tracking register with success!!!");
        setToastVariant("success");
        setToastIcon("emoji");

        logger.info("Bot tracking registerd", logArgs);
      })
      .catch((err: any) => {
        setToastText("😭");
        setToastText("Humm error on set bot tracking, please try again!");
        setToastVariant("error");
        setToastIcon("emoji-negative");

        logger.error("Error on set bot tracking", logArgs);
      })
      .finally(() => setToastStatus(true));
  }

  async function registerSegmentTracking(): Promise<void> {
    logArgs.methodName = registerSegmentTracking.name;

    const status = segment.isReady();
    if (status) {
      segment.track(1, "Clicked on register tracking");
      setToastTitle("Uhuu!");
      setToastText("Segment tracking register with success!!!");
      setToastIcon("emoji");
      setToastVariant("success");
      setToastIcon("emoji");

      logger.info("Segment log registered", logArgs);
    } else {
      setToastTitle("Hummm!");
      setToastText("Humm the segment key isn't setted!");
      setToastIcon("emoji-negative");
      setToastVariant("error");

      logger.error("Error on set segment track", logArgs);
    }
    setToastStatus(true);
  }

  async function getAppDataFromQueryString(): Promise<void> {
    logArgs.methodName = getAppDataFromQueryString.name;

    await appData
      .getAsync()
      .then((resp: any) => {
        logger.info("Adding user traits to local storage", logArgs);
        storage.add("userTraits", resp);

        setToastTitle("😍!");
        setToastText("App Data parsed with success!!!");
        setToastVariant("success");
        setToastIcon("emoji");
      })
      .catch((err: any) => {
        logger.error("Invalid app data!", logArgs);

        setToastText("Where is?");
        setToastText("App Data not found on query string!");
        setToastVariant("error");
        setToastIcon("emoji-negative");
      })
      .finally(() => setToastStatus(true));
  }

  return (
    <div className="app">
      <header className="app-header">
        <bds-toast
          class="msg-toast"
          variant={toastVariant}
          icon={toastIcon}
          show={toastStatus}
          toast-title={toastTitle}
          toast-text={toastText}
        />

        <bds-tooltip
          tooltip-text="Access the Flags API to change the flag and see another Blipinho!"
          class="app-button"
          position="bottom-center"
        >
          <FeatureFlag
            feature={"blipinho-thug-life"}
            onEnabled={
              <img src={blipinhoThugLife} className="app-logo" alt="logo" />
            }
            onDisabled={
              <img src={blipinhoBone} className="app-logo" alt="logo" />
            }
          />
        </bds-tooltip>

        <br />

        <div className="text">
          <p>Start to develop your own</p>
          <p>module or web application</p>
          <p>for packs right now!</p>
        </div>

        <div className="help-links">
          <a
            className="app-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>

          <a
            className="app-link"
            href="https://design.blip.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blip Design
          </a>

          <a
            className="app-link"
            href="https://docs.blip.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blip Docs
          </a>
        </div>

        <div className="current-info">
          <b>Current environment: </b>
          {environment.currentEnvironment}
        </div>

        <div>
          <b>Current log level: </b>
          {logger.currentLevel}
        </div>
        <div className="button-bar">
          <bds-tooltip
            tooltip-text="Get the app data and add to storage!"
            class="app-button"
            position="left-center"
          >
            <bds-button
              variant="primary"
              onClick={async () => getAppDataFromQueryString()}
            >
              Get AppData
            </bds-button>
          </bds-tooltip>

          <bds-tooltip
            tooltip-text="Register a bot track on blip!"
            class="app-button"
            position="top-center"
          >
            <bds-button
              variant="primary"
              onClick={async () => registerBotTracking()}
            >
              Register bot tracking
            </bds-button>
          </bds-tooltip>

          <bds-tooltip
            tooltip-text="Register a tracking on Segment!"
            position="right-center"
          >
            <bds-button
              variant="primary"
              onClick={() => registerSegmentTracking()}
            >
              Register segment tracking
            </bds-button>
          </bds-tooltip>
        </div>
      </header>
    </div>
  );
}

export default Home;
