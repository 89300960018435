import React from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "../home/Home";
import { logger, environment, segment, flags } from "packs-template-baseweb";

logger.configure();
logger.info(`Current environment: ${environment.currentEnvironment}`);
logger.info(`Current log level: ${logger.currentLevel}`);

function App(): JSX.Element {
  segment.configure();
  flags.configure();

  if (segment.isReady()) segment.autoTrackPages("userId");

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
