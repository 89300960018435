import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./pages/root/App";
import reportWebVitals from "./reportWebVitals";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { defineCustomElements, JSX as LocalJSX } from "blip-ds/loader";
import { BrowserRouter } from "react-router-dom";

//#region Settings for Bip-DS
type StencilToReactElements<T = LocalJSX.IntrinsicElements> = {
  [P in keyof T]?: T[P] &
    Omit<DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>, "className"> & {
      class?: string;
    };
};

type StencilToReactRef<T = HTMLElementTagNameMap> = {
  [P in keyof T]: {
    ref?: DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>["ref"];
  };
};

type StencilToReact<
  T = LocalJSX.IntrinsicElements,
  U = HTMLElementTagNameMap,
> = StencilToReactElements<T> & StencilToReactRef<U>;

declare global {
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact {}
  }
}

defineCustomElements(window);

//#endregion

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
